export default class API {
  constructor(gateway, apiName, root) {
    if (!gateway) throw new Error('No gateway supplied, can\'t make API calls')
    this.gateway = gateway
    this.apiName = apiName
    this.root = root
  }

  Get(path, queryParams) {
    const apiPath = `${this.apiName}/${path || ''}`

    return this.gateway.Get(apiPath, queryParams, this.root)
  }

  List() {
    return this.Get()
  }
}