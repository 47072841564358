var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  character: true,
  loaded: _vm.loaded,
  draggable: _vm.draggable,
  dragging: _vm.draggingEvent,
  hidden: _vm.hidden,
  development: _vm.development,
  drawerOpen: !!_vm.drawerOpen
},style:(_vm.style),on:{"contextmenu":_vm.OpenContext,"click":_vm.Click}},[(_vm.showContext && _vm.showContext.open)?_c('Context',{attrs:{"characterId":_vm.characterId},model:{value:(_vm.showContext),callback:function ($$v) {_vm.showContext=$$v},expression:"showContext"}}):_vm._e(),(_vm.showRendered)?_c('canvas',{ref:"characterCanvas",class:{ loaded: _vm.loaded }}):_vm._e(),(_vm.showOldImg)?_c('img',{ref:"characterImg",class:{
      loaded: _vm.loaded
    },attrs:{"src":_vm.characterAvatarUrl},on:{"load":function($event){_vm.loaded = true},"error":_vm.ErrorLoading}}):_vm._e(),(_vm.loaded && _vm.showControls)?_c('div',{staticClass:"dragging-coordinates"},[_vm._v(" "+_vm._s(_vm.characterData.position.x)+", "+_vm._s(_vm.characterData.position.y)+" ")]):_vm._e(),(_vm.development && _vm.draggable)?_c('v-btn',{staticClass:"character-id",attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.characterData.id)+" ")]):_vm._e(),(_vm.draggable && _vm.loaded && _vm.showControls)?_c('div',{staticClass:"controls",class:{ focused: _vm.focused }},[_c('v-speed-dial',{attrs:{"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"elevation":"2","color":"blue","fab":"","dark":""},on:{"click":_vm.OpenContext},model:{value:(_vm.speedOpen),callback:function ($$v) {_vm.speedOpen=$$v},expression:"speedOpen"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-dots-horizontal")])],1)]},proxy:true}],null,false,2741812884),model:{value:(_vm.speedOpen),callback:function ($$v) {_vm.speedOpen=$$v},expression:"speedOpen"}})],1):_vm._e(),(!_vm.loaded)?_c('v-skeleton-loader',{attrs:{"type":"image"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }