<template>
  <v-dialog v-model='IsOpen' max-width="512">
    <v-card class='dialog-api'>
      <v-card-title v-if='title'>
        {{ loading ? "Please wait..." : title }}
      </v-card-title>
      <v-card-text ref='container' class='list-container'>
        <SearchableVirtualScrollable
          :get-items='GetEntries'
          :item-width='itemWidth'
          :vuex-key='vuexKey'
          :entity-type='API.apiName'
          :region='region'
          :version='version'
          @select='SelectEntry'
          v-if='!loading'
          />
        <v-progress-linear
          indeterminate
          color="primary"
          v-else
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SearchableVirtualScrollable from './SearchableVirtualScrollable'

export default {
  name: 'App.Generic.Dialog',

  components: {
    SearchableVirtualScrollable
  },

  props: {
    API: Object,
    setting: String,
    title: String,
    itemWidth: {
      type: Number,
      default: 64
    }
  },

  data() {
    return {
      loading: false
    }
  },

  methods: {
    ...mapActions(['ApplySetting']),

    GetEntries() {
      return this.API.List()
    },

    SelectEntry(entryStub) {
      this.loading = true
      this.API.Get(entryStub.id)
        .then(entry => {
          this.$emit('select', entry)
          this.loading = false

          const props = { }
          props[this.vuexKey] = ''
          props[this.setting] = false
          this.ApplySetting(props)
        })
    }
  },

  watch: {
    IsOpen() {
      this.handleResize()
    }
  },

  computed: {
    ...mapState(['region', 'version']),

    IsOpen: {
      get() { return this.$store.state[this.setting] },
      set(isOpen) {
        const apply = {}
        apply[this.setting] = isOpen
        this.ApplySetting(apply)
      }
    },

    vuexKey() {
      return `${this.API.apiName}-searchFor`
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-api {
  overflow: hidden;
  max-height: calc(90vh - 48px);
  display: flex;
  flex-direction: column;

  .list-container {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
  }
}
</style>