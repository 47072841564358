import API from "./API";

export default class WZ extends API {
  constructor(gateway) {
    super(gateway, 'wz', true)
  }

  Query(path) {
    return this.Get(`${this.gateway.$store.state.region}/${this.gateway.$store.state.version}/${path}`)
  }

  List() {
    return this.Get(null, {
      cacheBust: Date.now()
    })
  }
}