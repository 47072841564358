/* eslint-disable no-console */

import { register } from 'register-service-worker'

export default function RegisterWorker($store) {
  register(`${process.env.BASE_URL}service-worker.js?t=${process.env.VUE_APP_GIT_HASH}`, {
    ready () {
      $store.dispatch('ServiceWorkerReady')
    },
    registered (reg) {
      $store.dispatch('ServiceWorkerRegistered', reg)
    },
    cached () {
      $store.dispatch('ServiceWorkerCached')
    },
    updatefound () {
      $store.dispatch('ServiceWorkerUpdateFound')
    },
    updated () {
      $store.dispatch('ServiceWorkerUpdated')
    },
    offline () {
      $store.dispatch('ServiceWorkerOffline')
    },
    error (error) {
      $store.dispatch('ServiceWorkerError', error)
    }
  })

  window.addEventListener('beforeinstallprompt', e => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    $store.dispatch('BeforeInstallPrompt', e)
  });

  window.addEventListener('appinstalled', () => {
    // Log install to analytics
    $store.dispatch('AppInstalled', true)
  });

  window.addEventListener('online', () => {
    $store.dispatch('NetworkStatus', true)
  })

  window.addEventListener('offline', () => {
    $store.dispatch('NetworkStatus', false)
  })
}
