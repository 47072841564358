import JSZip from "jszip"
import MapleStory from '@/Constants/MapleStory'
import CharacterMeta from '@/components/Character/Edit/Meta.vue'

export default class SpriteSheetGenerator {
  constructor() {

  }

  async AllFramesForAction(request, zipFile = undefined) {
    let target = zipFile
    if (!zipFile) {
      target = new JSZip()
    }

    const [maxFrames, maxFaceFrames] = await Promise.all([
      MapleStory.CharacterRenderer.GetMaxFrames(request),
      MapleStory.CharacterRenderer.GetMaxFaceFrames(request)
    ])

    const totalFrames = (maxFrames + 1) * (maxFaceFrames + 1)

    await Promise.all(Array.from({length: totalFrames}, async (_, frame) => {
      const frameRequest = { ...request, frame }
      const plan = await MapleStory.CharacterRenderer.GenerateRenderPlan(frameRequest)
      const rendered = await plan.Render()
      await new Promise((res, rej) => {
        rendered.toBlob(result => {
          console.log(`${frameRequest.action} / ${frameRequest.frame}`)
          if (result == null)
            rej(new Error(`Couldn't turn canvas for frame ${frame} into blob`))

            target.file(`${frame}.png`, result)
          res()
        })
      })

      return rendered
    }))

    return target
  }

  async AllFramesForAllActions(request, zipFile = undefined) {
    let target = zipFile
    if (!zipFile) {
      target = new JSZip()
    }

    console.log('Generating spritesheet')
    const possibleActions = CharacterMeta.computed.PossibleActions()
    console.log('All actions: ', possibleActions)

    await Promise.all(possibleActions.map(async actionData => {
      console.log(actionData)
      const action = actionData.value
      const actionRequest = { ...request, action }
      await this.AllFramesForAction(actionRequest, target.folder(action))
    }))

    return target
  }
}
