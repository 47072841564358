<template>
  <v-progress-linear
    indeterminate
    color="primary"
    v-if='loading'
  ></v-progress-linear>
  <v-list v-else class='scrollable-container'>
    <v-list-item>
      <v-text-field
        label='Search For'
        v-model='searchFor'
        />
    </v-list-item>
    <v-divider v-if='divider' />
    <v-list-item ref='container' class='scrollable-container-item'>
      <v-virtual-scroll
        class="api-scroller"
        :items="entryRows.rows"
        :item-height="48"
        :key='vuexKey+updatedAt+containerWidth+signal'
        v-if="entryRows.rows.length"
      >
        <template v-slot:default="{ item }">
          <div class="entry-row">
            <EntityIcon
              v-for='entry in item.items'
              :key='entry.id'
              :id='entry.id'
              :entityType='entityType'
              :region='region'
              :version='version'
              :title='entry.name'
              @click='Select(entry)'
              />
          </div>
        </template>
      </v-virtual-scroll>
      <div class='no-results' v-else>
        <p>No results found!</p>
        <a @click='OpenSettings'>Version: {{region}} / {{version}}</a>
      </div>
    </v-list-item>
  </v-list>
</template>

<script>
import Batcher from '@/Helpers/Batcher'
import EntityIcon from '@/components/EntityIcon'
import { mapActions, mapState } from 'vuex'
import DrawerNames from '@/Constants/Drawers'

export default {
  name: 'App.Generic.SearchableVirtualScrollable',

  components: {
    EntityIcon
  },

  props: {
    getItems: Function,
    itemWidth: {
      type: Number,
      default: 64
    },
    vuexKey: String,
    entityType: String,
    region: String,
    version: String,
    divider: Boolean,
    signal: String
  },

  data() {
    this.entries = []

    return {
      containerWidth: 0,
      loading: true,
      updatedAt: -1
    }
  },

  created() {
    this.FetchItems()

    window.addEventListener('resize', this.handleResize)
  },

  destroyed() { window.removeEventListener('resize', this.handleResize) },
  mounted() { this.handleResize() },
  updated() { this.handleResize() },

  watch: {
    searchFor() {
      this.FetchItems()
    },
    vuexKey() {
      this.FetchItems()
    }
  },

  methods: {
    ...mapActions(['ApplySetting', 'OpenDrawer']),

    OpenSettings() {
      this.OpenDrawer(DrawerNames.AppSettings)
    },

    FetchItems() {
      this.getItems().then(results => {
        this.entries = results
        this.updatedAt = Date.now()
        this.loading = false
      })
    },

    Select(entry) {
      this.$emit('select', entry)
    },

    handleResize() {
      this.$nextTick(() => {
        if (this.$refs.container) {
          if (this.$refs.container.$el)
            this.containerWidth = this.$refs.container.$el.clientWidth
          else
            this.containerWidth = this.$refs.container.clientWidth
        }
      })
    }
  },

  computed: {
    ...mapState(['development']),

    searchFor: {
      get() { return this.$store.state[this.vuexKey] },
      set(searchFor) {
        const props = { }
        props[this.vuexKey] = searchFor
        this.ApplySetting(props)
      }
    },

    EntriesPerRow() {
      if (!this.containerWidth) {
        this.handleResize()
        return 0
      }

      const width = this.containerWidth - 80
      const itemsPerRow = Math.floor(width / this.itemWidth)
      return itemsPerRow
    },

    entryRows() {
      let entries = this.entries

      if (this.searchFor) {
        entries = entries.filter(item => {
          const isMatch = item.name && (item.name.match(this.searchFor) || item.name.toLowerCase().match(this.searchFor.toLowerCase()))
          const isItemIdMatch = item.id == this.searchFor
          const isDescMatch = item.desc && (item.desc.match(this.searchFor) || item.desc.toLowerCase().match(this.searchFor.toLowerCase()))

          return isMatch || isItemIdMatch || isDescMatch
        })
      }

      return {
        updatedAt: this.updatedAt,
        rows: Batcher.CreateBatches(entries, this.EntriesPerRow)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.scrollable-container.v-list {
  display: flex;
  flex-direction: column;
  flex: 1 1;

  .v-list-item:not(.scrollable-container-item) {
    flex: 0 1;
  }

  .scrollable-container-item {
    flex: 1;

    .no-results {
      justify-content: center;
      align-items: center;
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .api-scroller {
      flex: 1;
      overflow-y: auto;

      .entry-row {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
