<template>
  <div class="container">
    <div class="message-container">
      <v-icon v-if="error">mdi-alert-circle</v-icon>
      <v-icon v-if="!error">mdi-file-download</v-icon>
      <div class="message">
        <div v-if="value.Done">Completed downloading</div>
        <div v-else-if="!value.RetryIndex">Downloading</div>
        <div v-else-if="value.RetryIndex">Downloading (Retry #{{value.RetryIndex}})</div>
        <div v-if="error" class="error">Error: {{error}}</div>
        <a class="file-name" :href="value.Name">{{value.Name}}</a>
        <div v-if="value.Done" class="note">
          <span>{{humanCompletedLength}}</span>
          <span v-if="!value.Length">From Cache</span>
          <span>{{downloadTime}}</span>
        </div>
      </div>
    </div>

    <div class="remaining-container" v-if="!value.Done">
      <div class="remaining" v-if="!value.Length">Pending...</div>
      <div class="remaining" v-else>{{humanCompletedLength}} / {{humanLength}} ({{percentile}}%)</div>
      <v-progress-linear
        :indeterminate="isIndeterminate"
        :value="percentile"
        color="blue"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "App.Notifications.Network",

  props: {
    value: Object
  },

  methods: {
    // Literally copied from stackoverflow, ty https://stackoverflow.com/a/14919494
    humanFileSize(bytes, si=false, dp=1) {
      const thresh = si ? 1000 : 1024;

      if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
      }

      const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
      let u = -1;
      const r = 10**dp;

      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


      return bytes.toFixed(dp) + ' ' + units[u];
    }
  },

  computed: {
    humanCompletedLength() {
      return this.humanFileSize(this.value.CompletedLength)
    },

    humanLength() {
      return this.humanFileSize(this.value.Length)
    },

    downloadTime() {
      let msElapsed = (this.value.DoneAt - this.value.StartedAt)

      const portions = [];

      const msInHour = 1000 * 60 * 60;
      const hours = Math.trunc(msElapsed / msInHour);
      if (hours > 0) {
        portions.push(hours + 'h');
        msElapsed = msElapsed - (hours * msInHour);
      }

      const msInMinute = 1000 * 60;
      const minutes = Math.trunc(msElapsed / msInMinute);
      if (minutes > 0) {
        portions.push(minutes + 'm');
        msElapsed = msElapsed - (minutes * msInMinute);
      }

      const seconds = Math.trunc(msElapsed / 1000);
      if (seconds > 0) {
        portions.push(seconds + 's');
        msElapsed = msElapsed - (seconds * 1000)
      }

      portions.push(msElapsed + "ms")

      if (!portions.length) return ""

      return "Took " + portions.join(' ');
    },

    percentile() {
      let percentage = (this.value.CompletedLength / this.value.Length) * 10000
      percentage = Math.round(percentage)
      percentage /= 100

      if (Number.isNaN(percentage)) return 0

      return percentage
    },

    isIndeterminate() {
      return !this.value.CompletedLength
    },

    error() {
      if (!this.value.Error) return

      return this.value.Error.message
    }
  }
}
</script>

<style scoped>
p {
  margin-bottom: 0!important;
  padding-left: 8px;
}

i {
  margin-right: 16px;
}

a.file-name {
  display: inline-block;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.container {
  margin: 0;
}

.message-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
}

.message {
  padding: 4px 0 4px 8px;
  margin: 8px 0;
  border-left: 1px solid black;
  display: block;
  flex: 1;
  min-width: 0;
}

.error {
  padding: 4px 16px;
  border-radius: 4px;
  display: inline-block;
}

.note {
  display: flex;
  justify-content: space-between;
  color: #888;
}
</style>