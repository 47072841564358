<template>
  <v-tooltip fixed top>
    <template v-slot:activator="{ on, attrs }">
      <div class='entity-icon' ref='entity'
        v-bind="attrs"
        v-on="on"
        @click='$emit("click")'
        :style='style'
      >
        <img
          :src='source'
          :class='{
            loaded
          }'
          @load='loaded = true'
          @error='error = true' />
        <v-skeleton-loader
          type='image'
          v-if='!loaded && !error'
          :width='`${maxWidth}px`'
          :height='`${maxHeight}px`'
          />
        <v-icon v-if='error'>mdi-alert-circle</v-icon>
      </div>
    </template>

    <span v-if='loaded'>{{ title }}</span>
    <span v-else-if="error">Something went wrong loading this</span>
    <span v-else>Loading...</span>
  </v-tooltip>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Icon',

  data: () => ({
    loaded: false,
    error: false
  }),

  props: {
    id: Number,
    title: String,
    version: String,
    region: String,
    entityType: {
      type: String,
      default: 'item'
    },
    maxWidth: {
      type: Number,
      default: 42
    },
    maxHeight: {
      type: Number,
      default: 42
    }
  },

  watch: {
    source() {
      this.loaded = false
      this.error = false
    }
  },

  computed: {
    ...mapState(['apiRoot']),

    style() {
      return {
        'max-width': this.maxWidth + 'px',
        'max-height': this.maxHeight + 'px',
        'min-width': this.maxWidth + 'px',
        'width': this.maxWidth + 'px'
      }
    },

    source() {
      return `${this.apiRoot}/${this.region}/${this.version}/${this.entityType}/${this.id}/icon`
    }
  }
}
</script>

<style lang="scss" scoped>
.entity-icon {
  padding: 4px;
  margin: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0px none transparent;
  border-radius: 4px;
  cursor: pointer;

  img {
    max-width: 100%;
    max-height: 100%;
    &:not(.loaded) {
      visibility: hidden;
      position: absolute;
    }
  }

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  }
}
</style>