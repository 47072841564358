<template>
  <v-navigation-drawer
      absolute
      permanent
      stateless
      :class='{
        "character-drawer": true,
        visible
      }'
      v-model='visible'
    >
    <div class='character-container'>
      <Character :id='focusedEntityId' v-if='focusedEntityId' :key='focusedEntityId' />
    </div>
    <v-tabs grow v-model='tab'>
      <v-tabs-slider></v-tabs-slider>
      <v-tab>Search</v-tab>
      <v-tab>Equipped</v-tab>
      <v-tab>Settings</v-tab>
    </v-tabs>
    <v-divider />
    <ItemList
      v-if='IsItemSearchOpen'
      @select='item => SetItem(item)'
      />
    <EquippedItems v-if='IsCharacterEditOpen' />
    <Meta v-if='IsMeta' />

  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Character from '../index'
import EquippedItems from './EquippedItems'
import Meta from './Meta'
import ItemList from './ItemList'
import DrawerNames from '@/Constants/Drawers'

const tabs = [
  DrawerNames.Character.Edit.ItemSearch,
  DrawerNames.Character.Edit.Equipped,
  DrawerNames.Character.Edit.Meta
]

export default {
  name: 'Character.Edit',

  components: {
    Character,
    EquippedItems,
    ItemList,
    Meta
  },

  props: {
    value: String
  },

  methods: {
    ...mapActions(['OpenDrawer', 'SetEntity']),

    SetItem(itemEntry) {
      const currentItems = {
        ...this.characterData.selectedItems
      }

      const character = {
        ...this.characterData,
        selectedItems: currentItems
      }

      currentItems[itemEntry.typeInfo.subCategory] = {
        ...itemEntry,
        region: this.region,
        version: this.version
      }

      this.SetEntity(character)
    }
  },

  computed: {
    ...mapState(['focusedEntityId', 'region', 'version']),
    ...mapGetters(['characters']),

    tab: {
      get() {
        switch (this.value) {
          case DrawerNames.Character.Edit.ItemSearch: return 0;
          case DrawerNames.Character.Edit.Equipped: return 1;
          case DrawerNames.Character.Edit.Meta: return 2;
        }

        return 0
      },
      set(value) {
        this.$emit('input', tabs[value])
      }
    },

    visible: {
      get() { return tabs.indexOf(this.value) !== -1 && !!this.focusedEntityId },
      set(value) {
        if (!value && this.visible)
          this.OpenDrawer(null)
      }
    },

    IsItemSearchOpen() { return this.value === DrawerNames.Character.Edit.ItemSearch },
    IsCharacterEditOpen() { return this.visible && this.value === DrawerNames.Character.Edit.Equipped },
    IsMeta() { return this.visible && this.value === DrawerNames.Character.Edit.Meta },

    characterData() {
      if (this.character) return this.character

      return this.characters[this.characterId]
    },

    characterId() {
      if (this.character) return this.character.id

      if (this.id) return this.id

      return this.focusedEntityId
    }
  }
}
</script>

<style lang="scss">
.character-drawer.v-navigation-drawer--absolute {
  width: 20vw!important;
  min-width: 312px;
  z-index: 11;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  transform: translateX(0)!important;

  &:not(.visible) {
    position:fixed;
    left: -100%;
  }

  .character-container {
    display: flex;
  }

  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
  }

  .character {
    display: flex;
    position: relative;
    max-height: 25vh;
    flex: 1 0;
  }

  .v-tabs {
    flex: 0 0 auto;
  }
}
</style>