<template>
  <div class="text-center" v-if="showDownloads">
    <v-menu
      top
      :style="{'z-index':10}"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
        >
          <v-icon>mdi-antenna</v-icon>
        </v-btn>
      </template>

      <v-list v-if="notifications.length > 0" :style="{'max-height': '50vh', 'overflow': 'auto'}">
        <v-list-item v-if="ActiveNetwork.length">
          <v-list-item-title><strong>Active network traffic:</strong></v-list-item-title>
        </v-list-item>
        <v-divider />

        <v-list-item
          v-for="(item, index) in ActiveNetwork"
          :key="`network-${index}`"
        >
          <v-list-item-title class="notification">
            <Network v-if="item.type === 'Network'" :value="item" />
          </v-list-item-title>
        </v-list-item>

        <v-list-item v-if="!ActiveNetwork.length">
          <v-list-item-title><strong>No active network traffic</strong></v-list-item-title>
        </v-list-item>

        <v-divider />

        <v-list-item>
          <v-list-item-title><strong>Past notifications:</strong></v-list-item-title>
        </v-list-item>
        <v-divider />

        <v-list-item
          v-for="(item, index) in PastNotifications"
          :key="index"
        >
          <v-list-item-title class="notification network">
            <Network v-if="item.type === 'Network'" :value="item" />
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <v-list v-else>
        <v-list-item>
          <v-list-item-title>
            No notifications yet!
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import MapleStory from '@/Constants/MapleStory'
import Network from './Notifications/Network.vue'
import Vue from 'vue'
import { mapState } from 'vuex'

export default {
  name: "App.Notifications",

  components: {
    Network
  },

  data: () => ({
    notifications: []
  }),

  computed: {
    ...mapState(['showDownloads']),
    ActiveNetwork() {
      return this.notifications.filter(notification => !notification.Done)
    },

    PastNotifications() {
      return this.notifications
      .filter(notification => this.ActiveNetwork.indexOf(notification) === -1) // Filter out active network traffic
    }
  },

  created() {
    MapleStory.Network.RegisterEventMonitor(this.HandleNetworkMonitor)
  },

  methods: {
    HandleNetworkMonitor(monitor) {
      monitor.type = "Network"
      this.notifications.unshift(monitor)

      monitor.RegisterNotifyOnComplete(moniterCopy => {
        // Force recalc
        Vue.set(this.notifications, this.notifications.indexOf(moniterCopy), moniterCopy)
      })
    }
  }
}
</script>

<style scoped>
.v-list-item + .v-list-item {
  border-top: 1px solid rgba(0,0,0, 0.3);
}
</style>