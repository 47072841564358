import API from "./API";

export default class Pets extends API {
  constructor(gateway) {
    super(gateway, 'item')
  }

  List() {
    return this.gateway.Get('pet')
  }
}
