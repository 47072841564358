function CreateRow(items) {
  return {
    id: items.map(item => item.id).join('-'),
    items
  }
}

function CreateBatches(items, itemsPerRow) {
  if (!itemsPerRow) return []

  let reduceTo = {
    totalRows: [],
    currentRow: []
  }

  const reduced = items.reduce((total, current) => {
    let { totalRows, currentRow } = total

    currentRow.push(current)

    if (currentRow.length >= itemsPerRow) {
      totalRows.push(CreateRow(currentRow))
      currentRow = []
    }

    return { totalRows, currentRow }
  }, reduceTo)

  const { totalRows, currentRow } = reduced

  if (currentRow.length > 0)
    totalRows.push(CreateRow(currentRow))

  return totalRows
}

export default {
  CreateRow,
  CreateBatches
}