<template>
  <v-list class='equipped-items-container'>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          Equipped Items
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-expansion-panels>
      <EquippedItemEntry
        v-for='item in currentItems'
        :key='item.name'
        class='item-entry'
        :item='item'
        :characterId='currentCharacter.id'
        />
    </v-expansion-panels>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex'
import EquippedItemEntry from './EquippedItemEntry'

export default {
  name: 'Character.Edit.EquippedItemsBar',

  components: { EquippedItemEntry },

  props: {
    value: Boolean
  },

  computed: {
    ...mapGetters(['currentCharacter', 'api']),

    currentItems() {
      return Object.values(this.currentCharacter.selectedItems)
    }
  }
}
</script>

<style lang="scss">
.equipped-items-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>