const defaultBackground = {
  "rgba":{
    "r":0,
    "g":0,
    "b":0,
    "a":0
  }
}

export default {
  characterItemEntries() {
    var items = Object.values(this.characterData.selectedItems)
      .filter(item => item.id && (item.id < 20000 || item.visible === undefined || item.visible))
      .map(item => {
        let itemEntry = {
          itemId: Number(item.id)
        }

        let isFace = (item.id >= 20000 && item.id < 30000) || (item.id >= 50000 && item.id < 60000)
        let isFaceAcc = (item.id >= 1010000 && item.id < 1020000)
        if (isFace || isFaceAcc) itemEntry.animationName = this.characterData.emotion
        itemEntry.region = item.region || this.region
        itemEntry.version = item.version || this.version
        if (item.hue) itemEntry.hue = item.hue
        if (item.saturation !== 1) itemEntry.saturation = item.saturation
        if (item.contrast !== 1) itemEntry.contrast = item.contrast
        if (item.brightness !== 1) itemEntry.brightness = item.brightness
        if (item.alpha !== 1) itemEntry.alpha = item.alpha
        if (item.islot) itemEntry.islot = item.islot
        if (item.vslot) itemEntry.vslot = item.vslot
        if (item.equipFrame) itemEntry.equipFrame = item.equipFrame
        if (item.disableEffect) itemEntry.disableEffect = item.disableEffect
        if (item.glow) itemEntry.glow = item.glow
        if (item.grayscale) itemEntry.grayscale = item.grayscale
        if (item.invert) itemEntry.invert = item.invert
        if (item.oilPaint) itemEntry.oilPaint = item.oilPaint
        if (item.sepia) itemEntry.sepia = item.sepia

        return itemEntry
      })

      if (!items.find(item => item.itemId < 10000))
        items.push({itemId: 2000, region: this.region, version: this.version, alpha: 0})
      if (!items.find(item => item.itemId < 20000 && item.itemId >= 10000))
        items.push({itemId: 12000, region: this.region, version: this.version, alpha: 0})

      return items
  },

  characterItemEntriesPayload() {
    let itemEntriesPayload = JSON.stringify(this.characterItemEntries)
    itemEntriesPayload = encodeURIComponent(
      itemEntriesPayload.substr(1, itemEntriesPayload.length - 2)
    )

    return itemEntriesPayload
  },

  linkParameters() {
    let {
      mercEars,
      illiumEars,
      highFloraEars,
      zoom,
      name,
      flipX,
      includeBackground,
      animating
    } = this.characterData

    const props = {
      'showears': mercEars,
      'showLefEars': illiumEars,
      'showHighLefEars': highFloraEars,
      'resize': zoom,
      'name': name,
      'flipX': flipX
    }

    const backgroundColor = this.background || defaultBackground
    if (backgroundColor && animating && includeBackground && backgroundColor.alpha) {
      const bgColorText = `${backgroundColor.rgba.r},${backgroundColor.rgba.g},${backgroundColor.rgba.b},${backgroundColor.rgba.a}`
      props.bgColor = bgColorText
    }

    Object.keys(props).forEach(key => {
      if (props[key] == null)
        delete props[key]
    })

    if (!this.draggable)
      delete props.resize

    return new URLSearchParams(props)
  },

  characterAvatarUrl() {
    if (!this.characterData) {
      return null
    }

    let {
      animating,
      action,
      frame
    } = this.characterData

    const url = [
      this.apiRoot,
      'character',
      this.characterItemEntriesPayload,
      action,
      animating ? 'animated' : frame
    ].join('/') + '?' + this.linkParameters.toString()

    return url
  },

  ExplodeLink() {
    let {
      action,
      frame
    } = this.characterData

    return [
      this.apiRoot,
      'character',
      this.characterItemEntriesPayload,
      action,
      frame,
      'download'
    ].join('/') + '?' + this.linkParameters.toString() + '&format=1'
  }
}