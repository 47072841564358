<template>
  <v-expansion-panel class='equipped-item-entry'>
    <v-expansion-panel-header>
      <div class='item-icon-container'>
        <img
          :src='`${apiRoot}/${item.region}/${item.version}/item/${item.id}/icon`'
          v-if='item.id > 12999'
          class='item-icon'
          />
      </div>
      <span class="v-list-item-text">{{item.name}}</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-list>
          <v-subheader>Frame</v-subheader>
          <v-slider min='0' max='10' thumb-label v-model='frame'>
            <template v-slot:append>
              <v-text-field type="number" v-model='frame' />
            </template>
          </v-slider>
          <v-subheader>Hue</v-subheader>
          <v-slider min='0' max='360' thumb-label v-model='hue'>
            <template v-slot:append>
              <v-text-field type="number" v-model='hue' />
            </template>
          </v-slider>
          <v-subheader>Contrast</v-subheader>
          <v-slider min='0' max='200' thumb-label v-model='contrast'>
            <template v-slot:append>
              <v-text-field type="number" v-model='contrast' />
            </template>
          </v-slider>
          <v-subheader>Brightness</v-subheader>
          <v-slider min='0' max='200' thumb-label v-model='brightness'>
            <template v-slot:append>
              <v-text-field type="number" v-model='brightness' />
            </template>
          </v-slider>
          <v-subheader>Saturation</v-subheader>
          <v-slider min='0' max='200' thumb-label v-model='saturation'>
            <template v-slot:append>
              <v-text-field type="number" v-model='saturation' />
            </template>
          </v-slider>
          <v-subheader>Alpha</v-subheader>
          <v-slider min='0' max='100' thumb-label v-model='alpha'>
            <template v-slot:append>
              <v-text-field type="number" v-model='alpha' />
            </template>
          </v-slider>
        <v-list-item>
          <v-switch
            v-model='disableEffect'
            label='Disable Effects'
            />
        </v-list-item>
        <v-list-item>
          <v-switch
            v-model='visible'
            label='Visible'
            />
        </v-list-item>
        <v-list-item>
          <v-btn @click='Remove' color='red' block :disabled='BlockDelete'>Remove</v-btn>
        </v-list-item>
      </v-list>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'Character.Edit.EquippedItems.Entry',

  data: () => ({
    settings: true
  }),

  props: {
    item: Object,
    characterId: Number
  },

  methods: {
    ...mapActions(['SetEntity']),

    Remove() {
      const character = {
        ...this.characterData
      }

      const selectedItems = {
        ...character.selectedItems,
      }

      delete selectedItems[this.item.typeInfo.subCategory]
      character.selectedItems = selectedItems

      this.SetEntity(character)
    },

    UpdateItem(newProps) {
      const newItem = {
        ...this.item,
        ...newProps
      }

      const character = {
        ...this.characterData
      }

      const selectedItems = {
        ...character.selectedItems,
      }

      selectedItems[newItem.typeInfo.subCategory] = newItem
      character.selectedItems = selectedItems
      this.SetEntity(character)
    }
  },

  computed: {
    ...mapState(['apiRoot']),
    ...mapGetters(['characters']),

    BlockDelete() { return this.item.id < 20000 },

    characterData() {
      if (this.character) return this.character

      return this.characters[this.characterId]
    },

    frame: {
      get() { return this.item.frame },
      set(frame) { this.UpdateItem({frame}) }
    },
    hue: {
      get() { return this.item.hue },
      set(hue) { this.UpdateItem({hue}) }
    },
    contrast: {
      get() { return (this.item.contrast == null ? 1 : this.item.contrast) * 100 },
      set(contrast) { this.UpdateItem({contrast: contrast / 100}) }
    },
    brightness: {
      get() { return (this.item.brightness == null ? 1 : this.item.brightness) * 100 },
      set(brightness) { this.UpdateItem({brightness: brightness / 100}) }
    },
    saturation: {
      get() { return (this.item.saturation == null ? 1 : this.item.saturation) * 100 },
      set(saturation) { this.UpdateItem({saturation: saturation / 100}) }
    },
    alpha: {
      get() { return (this.item.alpha == null ? 1 : this.item.alpha) * 100 },
      set(alpha) { this.UpdateItem({alpha: alpha / 100}) }
    },
    disableEffect: {
      get() { return this.item.disableEffect },
      set(disableEffect) { this.UpdateItem({disableEffect}) }
    },
    visible: {
      get() {
        if (this.BlockDelete) return this.alpha > 0
        return this.item.visible === undefined ? true : this.item.visible
      },
      set(visible) {
        if (this.BlockDelete) {
          if (visible) this.UpdateItem({alpha: 1})
          else this.UpdateItem({alpha: 0})

          return
        }

        this.UpdateItem({visible})
      }
    }
  }
}
</script>

<style lang="scss">
.equipped-item-entry {
  .item-icon-container {
    width: 60px;
    min-height: 60px;
    max-width: 60px;
    margin-right: 8px;
    background: rgba(0, 0, 0, 0.1);
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    .item-icon {
      max-width: 48px;
    }
  }

  .v-input__slider .v-input__append-outer .v-input {
    width: 60px;
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
