var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  entity: true,
  loaded: _vm.loaded,
  draggable: _vm.draggable,
  dragging: _vm.draggingEvent,
  hidden: _vm.hidden,
  development: _vm.development,
  drawerOpen: !!_vm.drawerOpen
},style:(_vm.style),on:{"click":_vm.Click}},[_c('img',{ref:"entityImg",class:{
      loaded: _vm.loaded
    },attrs:{"src":_vm.url},on:{"load":function($event){_vm.loaded = true},"error":_vm.ErrorLoading}}),(_vm.loaded)?_c('div',{staticClass:"dragging-coordinates"},[_vm._v(" "+_vm._s(_vm.entity.position.x)+", "+_vm._s(_vm.entity.position.y)+" ")]):_vm._e(),(_vm.development && _vm.draggable)?_c('v-btn',{staticClass:"entity-id",attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.entity.id)+" ")]):_vm._e(),(!_vm.loaded)?_c('v-skeleton-loader',{attrs:{"type":"image"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }