<template>
  <div ref='container' class='item-list-container' v-if='itemsFetchedAt'>
    <v-list :class='{
      search: true
    }'>
      <v-list-item>
        <v-select
          v-model='category'
          label='Category'
          :items='Categories.categories'
          />
      </v-list-item>
      <v-list-item>
          <v-select
            v-model='subCategory'
            label='Sub Categories'
            :items='SubCategories.subCategories'
            />
      </v-list-item>
    </v-list>
    <SearchableVirtualScrollable
      :get-items='GetEntries'
      :item-width='44'
      :vuex-key='`${vuexKey}-${category}-${subCategory}`'
      entity-type='item'
      :region='region'
      :version='version'
      :signal='searchFor'
      class='scroller'
      @select='e => $emit("select", e)'
      @search='query => searchFor = query'
      />
  </div>
  <div v-else class='item-list-loading' ref='container'>
    <v-skeleton-loader type="card-heading, card-heading, card-heading" />
    <v-skeleton-loader
      :type="`avatar@${15}`"
      class='item-list-loader'
    ></v-skeleton-loader>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import API from '@/API'
import SearchableVirtualScrollable from '@/components/Generic/SearchableVirtualScrollable'

export default {
  name: 'Character.Edit.ItemList',

  components: { SearchableVirtualScrollable },

  data() {
    this.nonReactive = {
      itemFetch: Promise.resolve()
    }
    return {
      itemsFetchedAt: 0,
      vuexKey: 'character-list-item-searchFor'
    }
  },

  created() {
    this.fetchItems()
  },

  watch: {
    category(newCategory) {
      const subCategoriesAvailable = this.nonReactive.organizedItems[newCategory]
      const firstSubCategory = Object.keys(subCategoriesAvailable).shift()
      this.subCategory = firstSubCategory
      this.searchFor = ''
    },

    subCategory() {
      this.searchFor = ''
    },
  },

  computed: {
    ...mapState(['itemListCategory', 'itemListSubCategory', 'region', 'version']),

    category: {
      get() { return this.itemListCategory || 'Character' },
      set(itemListCategory) { this.ApplySetting({itemListCategory}) }
    },

    subCategory: {
      get() { return this.itemListSubCategory || 'Face' },
      set(itemListSubCategory) { this.ApplySetting({itemListSubCategory}) }
    },

    Categories() {
      if (!this.itemsFetchedAt)
        return null

      return {
        fetchedAt: this.itemsFetchedAt,
        categories: Object.keys(this.nonReactive.organizedItems)
      }
    },

    SubCategories() {
      if (!this.itemsFetchedAt || !this.nonReactive.organizedItems[this.category])
        return {}

      return {
        fetchedAt: this.itemsFetchedAt,
        subCategories: Object.keys(this.nonReactive.organizedItems[this.category])
      }
    },

    searchFor: {
      get() { return this.$store.state[this.vuexKey] },
      set(searchFor) {
        const props = { }
        props[this.vuexKey] = searchFor
        this.ApplySetting(props)
      }
    },

  },

  methods: {
    ...mapActions(['SetEntity', 'ApplySetting']),

    fetchItems() {
      this.nonReactive.itemFetch = this.nonReactive.itemFetch.then(() => {
         return API.Items.Equips().then(result => {
            this.nonReactive.items = result
            this.nonReactive.organizedItems = this.OrganizeItems(result)
            this.itemsFetchedAt = Date.now()
         })
      })
    },

    GetEntries() {
      if (!this.nonReactive.organizedItems) {
        return Promise.resolve([])
      }

      if (this.searchFor) {
        return Promise.resolve(this.nonReactive.items.reduce((total, item) => {
          if (total.length > 100)
            return total

          const isMatch = item.name && (item.name.match(this.searchFor) || item.name.toLowerCase().match(this.searchFor.toLowerCase()))
          const isItemIdMatch = item.id == this.searchFor
          const isDescMatch = item.desc && (item.desc.match(this.searchFor) || item.desc.toLowerCase().match(this.searchFor.toLowerCase()))

          const isMatching = isMatch || isItemIdMatch || isDescMatch

          if (isMatching)
            total.push(item)

          return total
        }, []))
      }

      const category = this.nonReactive.organizedItems[this.category]
      if (!category) {
        return Promise.resolve([])
      }
      const subCategory = category[this.subCategory]
      if (!subCategory){
        return Promise.resolve([])
      }

      return Promise.resolve(subCategory)
    },

    OrganizeItems(items) {
      const categories = items.reduce((total, current) => {
        let categoryHolder = total[current.typeInfo.category]
        let subCategoryHolder, subCategoryHolderAll, subCategoryHolderAll2, subCategoryHolderAll3,
          categoryHolderAll = total['All']
        if (!categoryHolder)
          categoryHolder = total[current.typeInfo.category] = {}
        if (!(subCategoryHolderAll = categoryHolder['All']))
          subCategoryHolderAll = categoryHolder['All'] = []
        if (!(subCategoryHolderAll2 = categoryHolderAll['All']))
          subCategoryHolderAll2 = categoryHolderAll['All'] = []
        if (!(subCategoryHolderAll3 = categoryHolderAll[current.typeInfo.subCategory]))
          subCategoryHolderAll3 = categoryHolderAll[current.typeInfo.subCategory] = []
        if (!(subCategoryHolder = categoryHolder[current.typeInfo.subCategory]))
          subCategoryHolder = categoryHolder[current.typeInfo.subCategory] = []

        subCategoryHolder.push(current)
        subCategoryHolderAll.push(current)
        subCategoryHolderAll2.push(current)
        subCategoryHolderAll3.push(current)
        return total
      }, {All: []})

      const allCategory = categories.All
      const allSubCategory = allCategory.All
      delete allCategory.All
      delete allCategory['Test Armor']
      delete allCategory['Test Weapon']
      categories.All = Object.keys(allCategory).sort().reduce((total, current) => {
        total[current] = allCategory[current]
        return total
      }, { All: allSubCategory })

      delete categories.Monster
      delete categories.Character.Head
      delete categories.Armor['Test Armor']
      delete categories.Other
      delete categories['Secondary Weapon']

      return categories
    }
  }
}
</script>

<style lang="scss">
.item-list-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 512px;

  .v-list.search {
    padding-bottom: 0;
  }

  .v-list.scrollable-container {
    margin-top: -8px;
  }

  .scroller {
    flex: 1 0;
    overflow-y: auto;

    .scrollable-container-item {
      padding: 0;
    }
  }
}

.item-list-loading {
  .item-list-loader.v-skeleton-loader {
    display: flex;
    flex-wrap: wrap;
    padding: 0 12px;
    margin: 0 auto;

    .v-skeleton-loader__avatar {
      border-radius: 4px;
      flex: 1 1 auto;
      margin: 4px;
      height: 42px;
      width: 42px;
    }
  }
}
</style>