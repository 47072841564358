<template>
  <v-list class='character-meta-container'>
    <v-list-item class='title'>
      Character Settings
    </v-list-item>
    <v-list-item v-if='development'>
      <v-text-field
        v-model='characterData.id'
        label='Id'
        disabled
        />
    </v-list-item>
    <v-list-item v-if='development'>
      <v-text-field
        v-model='characterData.position.x'
        label='X'
        disabled
        />
      <v-text-field
        v-model='characterData.position.y'
        label='Y'
        disabled
        />

    </v-list-item>
    <v-list-item>
      <v-text-field
        v-model='name'
        label='Name'
        />
    </v-list-item>
    <v-list-item>
      <v-select
        v-model='Skin'
        :items='PossibleSkins'
        label='Skin'
        ref='skin'
        @blur='Blur'
        @change='e => SelectChange(e, $refs.skin)'
        attach
        />
    </v-list-item>
    <v-list-item>
      <v-select
        v-model='Action'
        :items='PossibleActions'
        label='Pose / Action'
        ref='action'
        @blur='Blur'
        @change='e => SelectChange(e, $refs.action)'
        attach
        />
    </v-list-item>
    <v-list-item>
      <v-select
        v-model='FaceExpression'
        :items='PossibleFaces'
        label='Facial Expression'
        ref='face'
        @blur='Blur'
        @change='e => SelectChange(e, $refs.face)'
        attach
        />
    </v-list-item>
    <v-list-item>
      <v-slider
        min='0'
        max='10'
        v-model='frame'
        label='Frame'
        thumb-label
        />
    </v-list-item>
    <v-list-item>
      <v-slider
        min='0.5'
        max='5'
        step="0.1"
        v-model='zoom'
        label='Zoom'
        thumb-label
        />
    </v-list-item>
    <v-list-item>
      <HairDye :id="id" />
    </v-list-item>
    <v-list-item>
      <v-switch
        v-model='animating'
        label='Animated'
        />
    </v-list-item>
    <v-list-item>
      <v-switch
        v-model='includeBackground'
        label='Include Background'
        :disabled='!animating || !background.alpha'
        />
    </v-list-item>
    <v-list-item>
      <v-switch
        v-model='illiumEars'
        label='Flora Ears'
        />
    </v-list-item>
    <v-list-item>
      <v-switch
        v-model='highFloraEars'
        label='High Flora Ears'
        />
    </v-list-item>
    <v-list-item>
      <v-switch
        v-model='mercEars'
        label='Mercedes Ears'
        />
    </v-list-item>
    <v-list-item>
      <v-switch
        v-model='flipX'
        label='Flip Horizontally'
        />
    </v-list-item>
    <v-list-item>
      <v-switch
        v-model='visible'
        label='Visible'
        />
    </v-list-item>
    <v-divider />
    <v-list-item class='title'>
      Tools
    </v-list-item>
    <v-list-item>
      <v-btn block color="primary" @click='Export'> Export </v-btn>
    </v-list-item>
    <v-list-item>
      <v-btn block color="secondary" @click='Clone'> Clone </v-btn>
    </v-list-item>
    <v-divider />
    <v-list-item class='title'>
      Links
    </v-list-item>
    <v-list-item>
      <v-btn block color="secondary" target='_blank' :href='characterAvatarUrl'>Download</v-btn>
    </v-list-item>
    <v-list-item>
      <v-btn block color="secondary" target='_blank' :href='AnimatedCharacterLink'>Animated</v-btn>
    </v-list-item>
    <v-divider />
    <v-list-item class='title'>
      Spritesheet Downloads
    </v-list-item>
    <v-list-item>
      <v-btn block color="secondary" target='_blank' :href='DownloadLink' @click='GenerateSpritesheet'>Download</v-btn>
    </v-list-item>
    <v-list-item>
      <v-btn block color="secondary" target='_blank' :href='ExplodeLink'>Explode</v-btn>
    </v-list-item>
    <v-list-item>
      <v-btn block color="secondary" target='_blank' :href='`${DownloadLink}&format=2`'>Minimal</v-btn>
    </v-list-item>
    <v-list-item>
      <v-btn block color="secondary" target='_blank' :href='`${DownloadLink}&format=1`'>Layered</v-btn>
    </v-list-item>
    <v-list-item>
      <v-btn block color="secondary" target='_blank' :href='`${DownloadLink}&format=3`'>Minimal Layered</v-btn>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-btn block color="red" @click='PromptDelete'>Delete</v-btn>
    </v-list-item>
  </v-list>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import CommonComputed from '../Common'
import API from '../../../API'
import SpriteSheetGenerator from '../../../Helpers/SpriteSheetGenerator'
import { saveAs } from 'file-saver'
import HairDye from './HairDye'

const defaultSkins = {
  2000: "Light" ,
  2004: "Ashen" ,
  2010: "Pale Pink" ,
  2001: "Tanned" ,
  2003: "Pale" ,
  2005: "Green" ,
  2013: "Ghostly" ,
  2002: "Dark" ,
  2011: "Clay" ,
  2009: "White" ,
  2012: "Mercedes" ,
  2015: "Soft Petal" ,
  2016: "Blushing Petal" ,
  2018: "라벤더 뽀송" ,
  2019: "라벤더 홍조"
}

export default {
  name: "Character.Edit.Meta",

  props: {
    id: Number
  },

  components: {
    HairDye
  },

  created() { this.FetchSkins() },
  watch: {
    region() { this.FetchSkins() },
    version() { this.FetchSkins() }
  },

  data: () => ({
    lastChange: null,
    skinNames: null,
    spriteSheetGenerator: new SpriteSheetGenerator()
  }),

  methods: {
    ...mapActions(['SetEntity', 'DeleteEntity', 'AddCharacter', 'SetEntityFocus', 'SetCameraOn', 'background']),

    GenerateSpritesheet(e) {
      if (!this.clientSideRender) return

      e.preventDefault()
      e.stopPropagation()

      console.log('Generating spritesheet')
      this.spriteSheetGenerator.AllFramesForAllActions(this.characterData)
        .then(zip => zip.generateAsync({type:"blob"}))
        .then(zip => {
          console.log('Saving...')
          saveAs(zip, "SpriteSheet.zip")
        })
    },

    FetchSkins() {
      const skinNames = {}
      this.skinNames = null
      API.WZ.Query('String/Eqp.img/Eqp/Skin').then(res => {
        const skinIds = res.children

        skinIds.reduce((total, current) => {
          const headId = current
          const skinId = current - 10000
          total[skinId] = null

          API.WZ.Query(`String/Eqp.img/Eqp/Skin/${headId}/name`).then(skinName => Vue.set(total, skinId, skinName.value))

          return total
        }, skinNames)

        this.skinNames = skinNames
      })
    },

    UpdateCharacter(newProps) {
      const character = {
        ...this.characterData,
        ...newProps
      }

      this.SetEntity(character)
    },

    SelectChange(e, componentReference) {
      this.lastChange = {
        el: componentReference.$refs.input,
        timestamp: Date.now()
      }
    },

    Blur(e) {
      // This is a hackish fix for Select elements auto
      // de-focusing after using arrow keys
      // If the last change was less than a second ago, auto re-focus the field
      if (!this.lastChange) return
      const difference = Date.now() - this.lastChange.timestamp
      if (difference < 1000) {
        this.$nextTick(() => e.target.focus())
      }
    },

    Clone() {
      const character = {
        ...this.characterData,
        id: Date.now()
      }

      this.AddCharacter(character)
      this.SetEntityFocus(character.id)
      this.SetCameraOn(character.id)
    },

    Export() {
      const a = document.createElement('a')
      a.style = 'display: none;'
      document.body.appendChild(a)

      const payload = JSON.stringify(this.characterData, null, 2),
        blob = new Blob([payload], {type: 'octet/stream'}),
        url = window.URL.createObjectURL(blob)
      a.href = url
      if (this.characterData.name)
        a.download = this.characterData.name + '-data.json'
      else
        a.download = 'character-data.json'
      a.click()

      window.URL.revokeObjectURL(url)
      a.remove()
    },

    PromptDelete() {
      const confirmation = confirm('Do you want to delete this character?')

      if (!confirmation) return

      this.DeleteEntity(this.characterId)
    }
  },

  computed: {
    ...mapState(['apiRoot', 'focusedEntityId', 'region', 'version', 'development', 'clientSideRender']),
    ...mapGetters(['characters']),
    ...CommonComputed,

    characterData() {
      if (this.character) return this.character

      return this.characters[this.characterId]
    },

    characterId() {
      if (this.character) return this.character.id

      if (this.id) return this.id

      return this.focusedEntityId
    },

    Skin: {
      get() { return this.characterData.selectedItems.Body && this.characterData.selectedItems.Body.id },
      set(value)
      {
        const skinNames = this.skinNames || defaultSkins

        const currentItems = {
          ...this.characterData.selectedItems
        }

        currentItems.Head = {
          "name": ((skinNames[value] || defaultSkins[value] || "") + " (Head)").trimLeft(),
          "noIcon": true,
          "id": 10000 + value,
          "region": this.region,
          "version": this.version,
          "typeInfo": {
            "overallCategory": "Character",
            "category": "Character",
            "subCategory": "Head",
            "lowItemId": 12000,
            "highItemId": 12999
          }
        }

        currentItems.Body = {
          "name": ((skinNames[value] || defaultSkins[value] || "") + " (Body)").trimLeft(),
          "skinName": skinNames[value] || defaultSkins[value],
          "noIcon": true,
          "id": value,
          "region": this.region,
          "version": this.version,
          "typeInfo": {
            "overallCategory": "Character",
            "category": "Character",
            "subCategory": "Body",
            "lowItemId": 2000,
            "highItemId": 2999
          }
        }

        const character = {
          ...this.characterData,
          selectedItems: currentItems
        }

        this.SetEntity(character)
      }
    },

    PossibleSkins() {
      let skinNames = this.skinNames || defaultSkins
      skinNames = { ...skinNames }
      const currentBody = this.characterData.selectedItems.Body
      if (!skinNames[currentBody.id] && currentBody.skinName) skinNames[currentBody.id] = currentBody.skinName
      if (skinNames[currentBody.id]) skinNames[currentBody.id] = skinNames[currentBody.id] + " (active)"

      return Object.keys(skinNames).reduce((total, skinId) => {
        total.push({value: Number(skinId), text: skinNames[skinId] || defaultSkins[skinId] || 'Loading...'})
        return total
      }, [])
    },

    Action: {
      get() { return this.characterData.action },
      set(action) { this.UpdateCharacter({action}) }
    },

    PossibleActions() {
      return [
        { "value": "stand1", "text": "Standing (1-Handed)" },
        { "value": "stand2", "text": "Standing (2-Handed)" },
        { "value": "walk1", "text": "Walking (1-Handed)" },
        { "value": "walk2", "text": "Walking (2-Handed)" },
        { "value": "alert", "text": "Alert" },
        { "value": "fly", "text": "Flying" },
        { "value": "heal", "text": "Healing" },
        { "value": "jump", "text": "Jumping" },
        { "value": "ladder", "text": "Ladder" },
        { "value": "rope", "text": "Rope" },
        { "value": "prone", "text": "Prone" },
        { "value": "proneStab", "text": "Prone + Stab" },
        { "value": "shoot1", "text": "Shoot (1-Handed)" },
        { "value": "shoot2", "text": "Shoot (2-Handed)" },
        { "value": "shootF", "text": "Shoot (0F)" },
        { "value": "sit", "text": "Sitting" },
        { "value": "stabO1", "text": "Stab (01)" },
        { "value": "stabO2", "text": "Stab (02)" },
        { "value": "stabOF", "text": "Stab (0F)" },
        { "value": "stabT1", "text": "Stab (T1)" },
        { "value": "stabT2", "text": "Stab (T2)" },
        { "value": "stabTF", "text": "Stab (TF)" },
        { "value": "swingO1", "text": "Swing (01)" },
        { "value": "swingO2", "text": "Swing (02)" },
        { "value": "swingO3", "text": "Swing (03)" },
        { "value": "swingOF", "text": "Swing (0F)" },
        { "value": "swingP1", "text": "Swing (P1)" },
        { "value": "swingP2", "text": "Swing (P2)" },
        { "value": "swingPF", "text": "Swing (PF)" },
        { "value": "swingT1", "text": "Swing (T1)" },
        { "value": "swingT2", "text": "Swing (T2)" },
        { "value": "swingT3", "text": "Swing (T3)" },
        { "value": "swingTF", "text": "Swing (TF)" },
      ]
    },

    FaceExpression: {
      get() { return this.characterData.emotion },
      set(emotion) { this.UpdateCharacter({ emotion }) }
    },

    PossibleFaces() {
      return [
        'angry',
        'bewildered',
        'blaze',
        'blink',
        'bowing',
        'cheers',
        'chu',
        'cry',
        'dam',
        'default',
        'despair',
        'glitter',
        'hit',
        'hot',
        'hum',
        'love',
        'oops',
        'pain',
        'qBlue',
        'shine',
        'smile',
        'stunned',
        'troubled',
        'vomit',
        'wink'
      ]
    },

    frame: {
      get() { return this.characterData.frame },
      set(frame) { this.UpdateCharacter({frame}) }
    },

    zoom: {
      get() { return this.characterData.zoom },
      set(zoom) { this.UpdateCharacter({zoom}) }
    },

    visible: {
      get() { return this.characterData.visible },
      set(visible) { this.UpdateCharacter({visible}) }
    },

    animating: {
      get() { return this.characterData.animating },
      set(animating) { this.UpdateCharacter({animating}) }
    },

    name: {
      get() { return this.characterData.name },
      set(name) { this.UpdateCharacter({name}) }
    },

    illiumEars: {
      get() { return this.characterData.illiumEars },
      set(illiumEars) { this.UpdateCharacter({illiumEars}) }
    },

    highFloraEars: {
      get() { return this.characterData.highFloraEars },
      set(highFloraEars) { this.UpdateCharacter({highFloraEars}) }
    },

    mercEars: {
      get() { return this.characterData.mercEars },
      set(mercEars) { this.UpdateCharacter({mercEars}) }
    },

    flipX: {
      get() { return this.characterData.flipX },
      set(flipX) { this.UpdateCharacter({flipX}) }
    },

    includeBackground: {
      get() { return this.animating && this.characterData.includeBackground && this.background.alpha },
      set(includeBackground) { this.UpdateCharacter({includeBackground}) }
    },

    characterAvatarUrl() {
      let {
        action,
        frame
      } = this.characterData

      const url = [
        this.apiRoot,
        'character',
        this.characterItemEntriesPayload,
        action,
        frame
      ].join('/') + '?' + this.linkParameters.toString()

      return url
    },

    AnimatedCharacterLink() {
      let {
        action
      } = this.characterData

      const url = [
        this.apiRoot,
        'character',
        this.characterItemEntriesPayload,
        action,
        'animated'
      ].join('/') + '?' + this.linkParameters.toString()

      return url
    },

    DownloadLink() {
      return [
        this.apiRoot,
        'character',
        this.characterItemEntriesPayload,
        'download'
      ].join('/') + '?' + this.linkParameters.toString()
    },

    ExplodeLink() {
      let {
        action,
        frame
      } = this.characterData

      return [
        this.apiRoot,
        'character',
        this.characterItemEntriesPayload,
        action,
        frame,
        'download'
      ].join('/') + '?' + this.linkParameters.toString() + '&format=1'
    }
  }
}
</script>

<style lang="scss" scoped>
.character-meta-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>