<template>
  <div @click='PreventPropagation' :style='{
      position: "absolute",
      top: value.y + "px",
      left: value.x + "px",
      "z-index": 10
  }'>
  <v-card class='context-menu' tile max-width="500">
    <v-list dense>
      <v-list-item @click='OpenItemSearch'>
        <v-list-item-icon>
          <v-icon>mdi-magnify</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          Add Item
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click='OpenInventory'>
        <v-list-item-icon>
          <v-icon>mdi-tshirt-crew</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          Equipment
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click='OpenMeta'>
        <v-list-item-icon>
          <v-icon>mdi-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          Settings
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item @click='Export'>
        <v-list-item-icon>
          <v-icon>mdi-account-arrow-down</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          Export
        </v-list-item-content>
      </v-list-item>
      <v-list-item :href='ExplodeLink'>
        <v-list-item-icon>
          <v-icon>mdi-download</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          Download Exploded
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DrawerNames from '@/Constants/Drawers'
import CommonComputed from './Common'
import Meta from './Edit/Meta'

export default {
  name: 'App.Character.Context',

  props: {
    value: Object,
    characterId: Number
  },

  data: () => ({
    globalClickHook: null
  }),

  computed: {
    ...mapState(['apiRoot']),
    ...mapGetters(['characters']),
    ...CommonComputed,

    characterData() {
      if (this.character) return this.character

      return this.characters[this.characterId]
    },
  },

  methods: {
    ...mapActions(['OpenDrawer', 'SetEntityFocus']),

    Export: Meta.methods.Export,

    PreventPropagation(e) {
      e.stopPropagation()
      e.preventDefault()
    },

    SelectDrawer(e, drawer) {
      e.preventDefault()

      this.$nextTick(() => {
        this.SetEntityFocus(this.characterId)
        this.OpenDrawer(drawer)

        this.$emit('input', {...this.value, open: false})
      })
    },

    OpenItemSearch(e) {
      this.SelectDrawer(e, DrawerNames.Character.Edit.ItemSearch)
    },

    OpenInventory(e) {
      this.SelectDrawer(e, DrawerNames.Character.Edit.Equipped)
    },

    OpenMeta(e) {
      this.SelectDrawer(e, DrawerNames.Character.Edit.Meta)
    },

    processClick(e) {
      if (e === this.value.e) return
      this.$emit('input', {...this.value, open: false})
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.globalClickHook = this.processClick.bind(this)
      window.addEventListener('click', this.globalClickHook)
      window.addEventListener('contextmenu', this.globalClickHook)
    })
  },

  destroyed() {
    window.removeEventListener('click', this.globalClickHook)
    window.removeEventListener('contextmenu', this.globalClickHook)
  }
}
</script>

<style lang="scss" scoped>
.context-menu {
  white-space: nowrap;
}
</style>
