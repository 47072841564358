import API from "./API";

export default class Items extends API {
  constructor(gateway) {
    super(gateway, 'item')
  }

  Equips() {
    return this.Get('category/equip')
  }
}
