<template>
  <v-navigation-drawer
    absolute
    temporary
    class='character-list-drawer'
    v-model='visible'
  >
    <v-list>
      <v-list-item>
        <v-btn block href='https://discord.gg/3SyrbAs' target='_blank'>
          <img src='@/assets/discord-logo.png' />
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-btn block class='kakao' href='https://open.kakao.com/o/gAdw6ZWb' target='_blank'>
          <img src='@/assets/KakaoTalk_logo.svg' />
        </v-btn>
      </v-list-item>
    </v-list>
    <v-expansion-panels>
      <Entry
        v-for='character in characters'
        :key='character.id'
        :character='character'
        />
    </v-expansion-panels>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Entry from './Entry'
import DrawerNames from '@/Constants/Drawers'

export default {
  name: 'Character.List',

  components: {
    Entry
  },

  props: {
    value: Boolean
  },

  methods: {
    ...mapActions(['OpenDrawer'])
  },

  computed: {
    ...mapState(['drawerOpen']),
    ...mapGetters(['characters']),

    visible: {
      get() { return this.drawerOpen === DrawerNames.Character.List },
      set(value) {
        if (value && !this.visible)
          this.OpenDrawer(DrawerNames.Character.List)
        else if (!value && this.visible)
          this.OpenDrawer(null)
      }
    }
  }
}
</script>

<style lang="scss">
.character-list-drawer {
  .v-btn {
    img {
      height: 36px;
    }

    &.kakao {
      background-color: #ffe814!important;

      &:before {
        background-color: transparent;
      }
    }
  }
}
</style>