<template>
  <div :class='{
    entity: true,
    loaded,
    draggable,
    dragging: draggingEvent,
    hidden,
    development,
    drawerOpen: !!drawerOpen
  }' :style='style' @click='Click'>
    <img
      :src='url'
      @load='loaded = true'
      @error='ErrorLoading'
      :class='{
        loaded
      }'
      ref='entityImg'
      />
    <div class='dragging-coordinates' v-if='loaded'>
      {{ entity.position.x }}, {{ entity.position.y }}
    </div>
    <v-btn v-if='development && draggable' class='entity-id' color='secondary'>
      {{ entity.id }}
    </v-btn>
    <v-skeleton-loader type='image' v-if='!loaded' />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SimDraggable from '@/Helpers/SimDraggable'

export default {
  name: 'App.Entity',

  props: {
    id: Number,
    draggable: Boolean
  },

  mounted() {
    if (this.draggable) {
      console.log('Initializing Dragging')
      this.dragging.init(this.$parent.$el, this.$el)
    }
  },

  destroyed() {
    if (this.draggable) {
      console.log('Unbinding dragging')
      this.dragging.destroy()
    }
  },

  data: function() {
    return {
      loaded: false,
      dragging: new SimDraggable(this),
      speedOpen: false,
      draggingEvent: false,
      error: false,
      retryCount: 0
    }
  },

  methods: {
    ...mapActions(['SetEntityFocus', 'OpenDrawer', 'SetEntity']),

    Click(e) {
      if (e.defaultPrevented || !this.draggable) return

      e.preventDefault()
      this.SetEntityFocus(this.id)

      return false
    },

    Move(x, y) {
      const entity = {
        ...this.entity
      }

      entity.position = {
        x: entity.position.x - Math.floor(x),
        y: entity.position.y - Math.floor(y)
      }

      if (Number.isNaN(entity.position.x) || Number.isNaN(entity.position.y))
        return

      this.SetEntity(entity)
    },

    ErrorLoading(e) {
      console.warn(`Error loading ${this.entity.type}`, e)
      if (this.retryCount < 3)
        this.retryCount++
      else
        this.error = true
    },

  },

  computed: {
    ...mapState(['entities', 'apiRoot', 'region', 'version', 'drawerOpen', 'development']),

    entity() {
      return this.entities[this.id]
    },

    style() {
      if (!this.draggable) return {}

      return {
        left: this.x + 'px',
        top: this.y + 'px'
      }
    },

    focused() {
      return this.characterId === this.focusedEntityId
    },

    url() {
      return [
        this.apiRoot,
        this.entity.region || this.region,
        this.entity.version || this.version,
        this.entity.type,
        this.entity[this.entity.type + 'Id'],
        'render',
        this.entity.action,
        this.entity.frame || 0
      ].join('/')
    },

    x: {
      get() { return this.entity.position.x },
      set(value) { this.entity.position.x = value }
    },

    y: {
      get() { return this.entity.position.y },
      set(value) { this.entity.position.y = value }
    },

    hidden() { return this.entity.visible != null && !this.entity.visible }
  },

  watch: {
    url() {
      this.loaded = false
      this.error = false
      this.retryCount = 0
    },
  }
}
</script>

<style scoped lang="scss">
.entity {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: flex-end;

  &.hidden.draggable {
    display: none;
  }

  &:not(.draggable) {
    min-height: 100px;
  }

  &.draggable {
    cursor: pointer;

    &:not(.loaded) {
      width: 100px;
    }

    img {
      top: 0;
    }

    &.drawerOpen .controls.focused {
      opacity: 0;
      visibility: hidden;
    }

    .controls {
      position: absolute;
      left: 50px;
      top: 0;
      transition: all 0.5s;
      opacity: 0;
      visibility: hidden;

      &.focused {
        opacity: 1;
        visibility: visible;
      }

      > *:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  .dragging-coordinates {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    padding: 4px 8px;
    white-space: nowrap;
    border-radius: 4px;
    color: white;
    opacity: 0;
    transition: all 0.25s;
  }

  .entity-id {
    position: absolute;
    bottom: 50px;
  }

  &.dragging .dragging-coordinates, &.development.draggable .dragging-coordinates {
    opacity: 1;
  }

  img {
    visibility: hidden;
    position: absolute;

    &.loaded {
      visibility: visible;
    }
  }
}

.v-skeleton-loader {
  width: 100%;
  height: 100%;

  max-height: 100px;
}
</style>