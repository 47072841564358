export default {
  character(character, state) {
    Object.values(character.selectedItems).forEach(item => {
      if (!item.region) item.region = state.region
      if (!item.version) item.version = state.version
      if (item.version === 'latest') item.version = state.version
    })

    if (!character.selectedItems.Body || !character.selectedItems.Head) {
      const skinId = character.skin || 2000
      character.selectedItems.Body = {
        "name": "Body",
        "noIcon": true,
        "id": skinId,
        "region": state.region,
        "version": state.version,
        "typeInfo": {
          "overallCategory": "Character",
          "category": "Character",
          "subCategory": "Body",
          "lowItemId": 2000,
          "highItemId": 2999
        }
      }
      character.selectedItems.Head = {
        "name": "Head",
        "noIcon": true,
        "id": 10000 + skinId,
        "region": state.region,
        "version": state.version,
        "typeInfo": {
          "overallCategory": "Character",
          "category": "Character",
          "subCategory": "Head",
          "lowItemId": 12000,
          "highItemId": 12999
        }
      }
    }

    return true
  },

  pet(pet, state) {
    if (!pet.actions || !pet.action) {
      if (state && state.entities) delete state.entities[pet.id]
      return false
    }
  }
}