<template>
  <div :class='{ loading: !mapReady, "canvas-container": true }'>
    <div v-if='camera' class='camera-text'>
      <div>
        <span class='dev' v-if='development'>{{camera.x}}, {{camera.y}} @ {{fps}}fps</span>
      </div>
      <audio controls :src='bgmPath' loop />
    </div>
    <canvas id='map-render' ref="renderTarget"
      :width='width'
      :height='height'
      @mousewheel="mouseWheel" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import API from '../../API'

export default {
  name: 'Map.Render',

  created () {
    this.StartPreview()
  },

  destroyed() {
    this.mapReady = false
    this.map = null
  },

  watch: {
    id() {
      this.mapReady = false
      this.map = null
      this.StartPreview()
    },

    map(newVal) {
      if (newVal)
        Vue.nextTick(this.InitializeRenderer.bind(this))
    }
  },

  props: {
    id: Number,
    region: String,
    version: String
  },

  data: function () {
    this.renderer = null

    return {
      mouseDown: false,
      lastMouse: null,
      lastTouch: null,
      mapReady: false,
      cameraRef: null,
      fps: 0,
      ShowPreview: false,
      map: null
    }
  },

  methods: {
    ...mapActions(['SetCamera']),

    StartPreview () {
      API.Maps.Get(this.id).then(map => {
        this.map = map
      })
    },

    InitializeRenderer () {

    },

    mouseWheel (e) {
      let scaleDelta = e.wheelDelta / 1000
      console.log(scaleDelta)
      this.renderer.Scale(scaleDelta)
      e.preventDefault()
    }
  },

  computed: {
    ...mapGetters(['api']),
    ...mapState(['camera', 'development']),

    bgmPath () { return [this.api, 'map', this.id, 'bgm'].join('/') },
    position() { return { x:0,y:0 } },

    internalCamera () {
      const globalCamera = this.camera

      return {
        x: Math.round(globalCamera.x - this.position.x),
        y: Math.round(globalCamera.y - this.position.y),

        override: {
          x: this.map.vrBounds.left,
          y: this.map.vrBounds.top
        }
      }
    },

    width() {
      if (this.map && this.map.vrBounds) return this.map.vrBounds.width
      return 0
    },
    height() {
      if (this.map && this.map.vrBounds) return this.map.vrBounds.height
      return 0
    }
  }
}
</script>

<style scoped>
.dev {
  display: block;
}

.canvas-container {
  position: absolute;
}

.camera-text {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  background: rgba(255, 255, 255, 0.9);
  border-bottom-left-radius: 4px;
  box-shadow: 0 0 4px black;
}
</style>