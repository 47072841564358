<template>
  <div :class='{
    character: true,
    loaded,
    draggable,
    dragging: draggingEvent,
    hidden,
    development,
    drawerOpen: !!drawerOpen
  }'
  :style='style'
  @contextmenu='OpenContext'
  @click='Click'>
    <Context v-if='showContext && showContext.open' v-model="showContext" :characterId='characterId' />
    <canvas v-if='showRendered' ref='characterCanvas' :class='{ loaded }' />

    <img
      :src='characterAvatarUrl'
      @load='loaded = true'
      @error='ErrorLoading'
      :class='{
        loaded
      }'
      ref='characterImg'
      v-if='showOldImg'
      />
    <div class='dragging-coordinates' v-if='loaded && showControls'>
      {{ characterData.position.x }}, {{ characterData.position.y }}
    </div>
    <v-btn v-if='development && draggable' class='character-id' color='secondary'>
      {{ characterData.id }}
    </v-btn>
    <div class='controls' v-if='draggable && loaded && showControls' :class='{ focused }'>
      <v-speed-dial v-model='speedOpen' open-on-hover>
        <template v-slot:activator>
          <v-btn
            elevation="2"
            color="blue"
            fab
            dark
            v-model='speedOpen'
            @click='OpenContext'
          >
            <v-icon dark>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
      </v-speed-dial>
    </div>
    <v-skeleton-loader type='image' v-if='!loaded' />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import SimDraggable from '@/Helpers/SimDraggable'
import MapleStory from '@/Constants/MapleStory'
import Context from './Context'
import CommonComputed from './Common'

export default {
  name: 'App.Character',

  components: { Context },

  data: function() {
    return {
      loaded: false,
      dragging: new SimDraggable(this),
      speedOpen: false,
      draggingEvent: false,
      error: false,
      retryCount: 0,
      rendered: null,
      renderPromise: Promise.resolve(),
      showContext: null,
      frameOverride: 0,
      maxFrames: {},
      animationPlan: undefined,
      ctx: undefined
    }
  },

  props: {
    id: Number,
    character: Object,
    linkType: String,
    draggable: Boolean
  },

  mounted() {
    if (this.draggable) {
      console.log('Initializing Dragging')
      this.dragging.init(this.$parent.$el, this.$el)
    }

    if (this.showRendered) {
      this.InitRender()
    }
  },

  destroyed() {
    if (this.draggable) {
      console.log('Unbinding dragging')
      this.dragging.destroy()
    }
  },

  methods: {
    ...mapActions(['SetEntityFocus', 'SetEntity']),

    InitRender() {
      if (!this.showRendered) return
      if (!this.$refs.characterCanvas)
        this.$nextTick(() => {
          this.InitRender()
        })

      this.ctx = this.$refs.characterCanvas.getContext('2d')

      clearTimeout(this.animationTimeout)
      Promise.all([
        MapleStory.CharacterRenderer.GetMaxFaceFrames(this.characterData).then(max => this.maxFrames[this.characterData.emotion] = max),
        MapleStory.CharacterRenderer.GetMaxFrames(this.characterData).then(max => this.maxFrames[this.characterData.action] = max)
      ]).then(() => this.Render())
    },

    Render() {
      if (!this.showRendered) return

      if (!this.$refs.characterCanvas) {
        console.log('Canvas gone :(')
        return
      }

      const realRequest = {
        ...this.characterData,
        frame: this.frame
      }

      if (this.animationPlan) {
        this.animationPlan.Stop()
      }

      if (this.characterData.animating) {
        console.log("Starting animation...")
        const renderPromise = this.renderPromise = this.renderPromise
          .then(() => MapleStory.CharacterRenderer.GenerateAnimatedRenderPlan(realRequest))
          .then(async plan => {
            if (this.renderPromise != renderPromise) return
            console.log("Got animated plan")
            plan.ReplaceCanvas(this.$refs.characterCanvas, this.ctx)
            this.animationPlan = plan

            await plan.RenderLoop()
            this.loaded = true
            console.log("Rendering", plan.context === this.ctx)
          }).catch(this.ErrorLoading)
      } else {
        const renderPromise = this.renderPromise = this.renderPromise
          .then(() => MapleStory.CharacterRenderer.GenerateRenderPlan(realRequest))
          .then(async plan => {
            if (this.renderPromise != renderPromise) return
            const renderedContext = await plan.Render()
            this.$refs.characterCanvas.width = renderedContext.width
            this.$refs.characterCanvas.height = renderedContext.height
            this.ctx.drawImage(renderedContext, 0, 0)

            this.loaded = true
          }).catch(this.ErrorLoading)
      }
    },

    OpenContext(e) {
      if ((this.showContext && this.showContext.open) || !this.draggable)
        return

      this.showContext = {
        x: e.offsetX,
        y: e.offsetY,
        open: true,
        e
      }
      e.preventDefault()
    },

    Click(e) {
      if (e.defaultPrevented || !this.draggable) return

      e.preventDefault()
      this.SetEntityFocus(this.characterId)

      return false
    },

    Move(x, y) {
      const character = {
        ...this.characterData
      }

      character.position = {
        x: character.position.x - Math.floor(x),
        y: character.position.y - Math.floor(y)
      }

      if (Number.isNaN(character.position.x) || Number.isNaN(character.position.y))
        return

      this.SetEntity(character)
    },

    ErrorLoading(e) {
      console.warn('Error loading character', e)
      if (this.retryCount < 3)
        this.retryCount++
      else
        this.error = true
    }
  },

  computed:{
    ...mapState(['apiRoot', 'focusedEntityId', 'region', 'version', 'background', 'drawerOpen', 'development', 'clientSideRender']),
    ...mapGetters(['characters']),
    ...CommonComputed,

    showOldImg() {
      return !this.clientSideRender
    },

    showRendered() {
      return this.clientSideRender
    },

    showControls() {
      return !this.showContext || !this.showContext.open
    },

    frame() {
      if (this.characterData.animating && this.showRendered)
        return this.frameOverride
      else return this.characterData.frame
    },

    style() {
      if (!this.draggable) return {}

      return {
        left: this.x + 'px',
        top: this.y + 'px'
      }
    },

    focused() {
      return this.characterId === this.focusedEntityId
    },

    characterData() {
      if (this.character) return this.character

      return this.characters[this.characterId]
    },

    characterId() {
      if (this.character) return this.character.id

      if (this.id) return this.id

      return this.focusedEntityId
    },

    x: {
      get() { return this.characterData.position.x },
      set(value) { this.characterData.position.x = value }
    },

    y: {
      get() { return this.characterData.position.y },
      set(value) { this.characterData.position.y = value }
    },

    hidden() { return !this.characterData.visible },

    hairDye() {
      return this.characterData.hairDye
    }
  },

  watch: {
    hairDye() {
      this.loaded = false
      this.error = false
      this.retryCount = 0

      this.InitRender()
    },

    characterAvatarUrl() {
      this.loaded = false
      this.error = false
      this.retryCount = 0

      this.InitRender()
    },

    clientSideRender(newValue) {
      if (newValue) {
        this.InitRender()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.character {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: flex-end;

  &.hidden.draggable {
    display: none;
  }

  &:not(.draggable) {
    min-height: 100px;
  }

  &.draggable {
    &:not(.loaded) {
      width: 100px;
    }

    img, canvas {
      top: 0;
    }

    &.drawerOpen .controls.focused {
      opacity: 0;
      visibility: hidden;
    }

    .controls {
      position: absolute;
      left: 50px;
      top: 0;
      transition: all 0.5s;
      opacity: 0;
      visibility: hidden;

      &.focused {
        opacity: 1;
        visibility: visible;
      }

      > *:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  .dragging-coordinates {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    padding: 4px 8px;
    white-space: nowrap;
    border-radius: 4px;
    color: white;
    opacity: 0;
    transition: all 0.25s;
    pointer-events: none;
  }

  .character-id {
    position: absolute;
    bottom: 50px;
  }

  &.dragging .dragging-coordinates, &.development.draggable .dragging-coordinates {
    opacity: 1;
  }

  img, canvas {
    visibility: hidden;
    position: absolute;

    &.loaded {
      visibility: visible;
    }
  }
}

.v-skeleton-loader {
  width: 100%;
  height: 100%;

  max-height: 100px;
}
</style>
