<template>
  <div class="hairdye" v-if="hairItem">
    Hair Dye
    <div class="colors">
      <div class="swatches">
        <button  @click="primaryHairId = 3" :class="{'active': primaryHairId == 3,'yellow': true}">&nbsp;</button>
        <button  @click="primaryHairId = 2" :class="{'active': primaryHairId == 2,'orange': true}">&nbsp;</button>
        <button  @click="primaryHairId = 1" :class="{'active': primaryHairId == 1,'red': true}">&nbsp;</button>
        <button  @click="primaryHairId = 4" :class="{'active': primaryHairId == 4,'green': true}">&nbsp;</button>
        <button  @click="primaryHairId = 6" :class="{'active': primaryHairId == 6,'purple': true}">&nbsp;</button>
        <button  @click="primaryHairId = 5" :class="{'active': primaryHairId == 5,'blue': true}">&nbsp;</button>
        <button  @click="primaryHairId = 0" :class="{'active': primaryHairId == 0,'black': true}">&nbsp;</button>
        <button  @click="primaryHairId = 7" :class="{'active': primaryHairId == 7,'brown': true}">&nbsp;</button>
      </div>
      <div class="swatches">
        <button  @click="secondaryHairId = 3" :class="{'active': secondaryHairId == 3,'yellow': true}">&nbsp;</button>
        <button  @click="secondaryHairId = 2" :class="{'active': secondaryHairId == 2,'orange': true}">&nbsp;</button>
        <button  @click="secondaryHairId = 1" :class="{'active': secondaryHairId == 1,'red': true}">&nbsp;</button>
        <button  @click="secondaryHairId = 4" :class="{'active': secondaryHairId == 4,'green': true}">&nbsp;</button>
        <button  @click="secondaryHairId = 6" :class="{'active': secondaryHairId == 6,'purple': true}">&nbsp;</button>
        <button  @click="secondaryHairId = 5" :class="{'active': secondaryHairId == 5,'blue': true}">&nbsp;</button>
        <button  @click="secondaryHairId = 0" :class="{'active': secondaryHairId == 0,'black': true}">&nbsp;</button>
        <button  @click="secondaryHairId = 7" :class="{'active': secondaryHairId == 7,'brown': true}">&nbsp;</button>
      </div>
    </div>
    <v-slider
      min='0'
      max='100'
      v-model='perc'
      thumb-label
      />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CommonComputed from '../Common'

export default {
  name: 'Character.Edit.HairDye',

  props: {
    id: Number
  },

  methods: {
    ...mapActions(['SetEntity', 'DeleteEntity', 'AddCharacter', 'SetEntityFocus', 'SetCameraOn', 'background']),

    UpdateCharacter(newProps) {
      const character = {
        ...this.characterData,
        ...newProps
      }

      this.SetEntity(character)
    }
  },

  computed: {
    ...mapState(['apiRoot', 'focusedEntityId', 'region', 'version', 'development', 'clientSideRender']),
    ...mapGetters(['characters']),
    ...CommonComputed,

    hairItem() {
      return this.characterData.selectedItems.Hair
    },

    primaryHairId: {
      get() {
        const existingHair = this.characterData.selectedItems.Hair
        const existingHairId = existingHair.id
        const baseHairId = Math.floor(existingHairId / 10) * 10
        return existingHairId - baseHairId
      },
      set(value) {
        const existingHair = this.characterData.selectedItems.Hair
        const existingHairId = existingHair.id
        const baseHairId = Math.floor(existingHairId / 10) * 10
        const mappedHairId = (baseHairId) + (value)

        const newHair = {
          ...existingHair,
          id: mappedHairId
        }

        this.UpdateCharacter({
          ...this.characterData,
          selectedItems:{
            ...this.characterData.selectedItems,
            Hair: newHair
          }
        })
      }
    },

    secondaryHairId: {
      get() {
        if (this.characterData.hairDye)
          return this.characterData.hairDye.colorId
        return this.primaryHairId
      },
      set(value) {
        const hairDye = this.characterData.hairDye
        this.UpdateCharacter({
          hairDye: {
            ...hairDye,
            colorId: value
          }
        })
      }
    },

    characterData() {
      if (this.character) return this.character

      return this.characters[this.characterId]
    },

    characterId() {
      if (this.character) return this.character.id

      if (this.id) return this.id

      return this.focusedEntityId
    },

    perc: {
      get() {
        const hairDye = this.characterData.hairDye
        if (hairDye) return hairDye.percentile * 100
        else return 0
      },
      set(value) {
        const hairDye = this.characterData.hairDye
        this.UpdateCharacter({
          hairDye: {
            colorId: 0,
            ...hairDye,
            percentile: value / 100
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.hairdye {
  flex-grow: 1;
}

.colors {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.swatches {
  width: 64px;
  margin: 16px 32px 16px 32px;
}

.swatches > * {
  display: inline-block;
  width: 32px;
  height:32px;
}

.swatches .active{
  outline: white solid;
  position: relative;
}
</style>