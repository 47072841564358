const DrawerNames = {
  name: 'DrawerNames',

  Character: {
    Edit: {
      Equipped: 'CHARACTER_EQUIPPED',
      ItemSearch: 'CHARACTER_ITEM_SEARCH',
      Meta: 'CHARACTER_META',
    },

    List: 'CHARACTER_LIST',
  },

  AppSettings: 'APP_SETTINGS'
}

export default DrawerNames