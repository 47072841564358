<template>
  <v-expansion-panel class='character-list-entry'>
    <v-expansion-panel-header>
      <Character :id='character.id' />
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-list>
        <v-list-item>
          <v-switch
            v-model='visible'
            label='Visible'
            />
        </v-list-item>
        <v-list-item>
          <v-btn block color='primary' @click='FocusOn'>Focus camera on</v-btn>
        </v-list-item>
        <v-list-item @click='Edit'>
          <v-btn block>Edit</v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn block @click='ExportCharacter'>Export</v-btn>
        </v-list-item>
      </v-list>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions } from 'vuex'
import Character from '../index'
import DrawerNames from '@/Constants/Drawers'

export default {
  name: 'Character.List.Entry',

  components: {
    Character
  },

  props: {
    character: Object
  },

  computed: {
    visible: {
      get() { return this.character.visible },
      set(visible) {
        this.SetEntity({
          ...this.character,
          visible
        })
      }
    }
  },

  methods: {
    ...mapActions(['SetCameraOn', 'OpenDrawer', 'SetEntityFocus', 'SetEntity']),

    FocusOn() {
      this.SetCameraOn(this.character.id)
      this.OpenDrawer(null)
    },

    Edit() {
      this.SetEntityFocus(this.character.id)
      this.OpenDrawer(DrawerNames.Character.Edit.Equipped)
    },

    ExportCharacter() {
      const a = document.createElement('a')
      a.style = 'display: none;'
      document.body.appendChild(a)

      const payload = JSON.stringify(this.character, null, 2),
        blob = new Blob([payload], {type: 'octet/stream'}),
        url = window.URL.createObjectURL(blob)
      a.href = url
      if (this.character.name)
        a.download = this.character.name + '-data.json'
      else
        a.download = 'character-data.json'
      a.click()

      window.URL.revokeObjectURL(url)
      a.remove()
    }
  }
}
</script>

<style lang="scss" scoped>
.character-list-entry {
  .character {
    position: relative;
    overflow: hidden;
  }
}
</style>