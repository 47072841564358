<template>
  <div class='updates-container'>
    <v-btn color='accent' v-if='Text' :disabled='!Updated' @click='Refresh'>
      <span>{{ Text }}</span>
    </v-btn>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'App.Updates',

  methods: {
    Refresh() {
      window.location = `${window.location.origin}/?t=${Date.now()}`
    }
  },

  computed: {
    ...mapState(['ServiceWorkerStatus']),

    Text() {
      if (this.UpdateFound && this.Updated)
        return 'Refresh for an update'

      if (this.UpdateFound)
        return 'Updating your app...'

      return false
    },

    UpdateFound() { return this.ServiceWorkerStatus.UpdateFound },
    Updated() { return this.ServiceWorkerStatus.Updated },
    Offline() { return this.ServiceWorkerStatus.Offline }
  }
}
</script>